<template>
  <v-row>
    <v-col :cols="6">
      <gw-text-field
        v-model.trim="product.name"
        :maxlength="200"
        :loading="loading"
        label="Product Name"
        :rules="rules.textFill" />
    </v-col>
    <v-col :cols="6">
      <gw-text-field
        v-model.trim="product.model"
        :loading="loading"
        label="Product Model"
        disabled />
    </v-col>
    <v-col cols="12">
      <!-- <gw-select
        v-model="product.gwCollection"
        :items="collectionItem"
        label="Collection"
        :loading="loading"
        item-text="name"
        item-value="id"
        dense /> -->
      <v-autocomplete
        v-model="product.gwCollection"
        label="Collection"
        color="secondary"
        :items="collectionItem"
        item-text="name"
        item-value="id"
        dense
        :loading="loading"
        :disabled="loading" />
    </v-col>
    <v-col cols="12">
      <gw-autocomplete
        v-model="product.groupCategories"
        :items="groupCategoryItem"
        :loading="loading"
        label="Group Categories"
        item-text="name"
        item-value="name"
        multiple
        small-chips
        clearable
        deletable-chips
        :return-object="false"
        @input="groupCategoryHandle($event)" />
    </v-col>
    <v-col cols="12">
      <gw-autocomplete
        v-model="product.productPrototype.category"
        :items="prototypeCategoriesItem"
        :loading="loading"
        label="Report Categories"
        item-text="name"
        item-value="name"
        :return-object="false" />
    </v-col>
    <v-col :cols="6">
      <gw-autocomplete
        v-model="product.categories"
        :items="categoriesItem"
        :rules="{
          required: true,
          oneOf: categoriesItem.map((r) => r.name)
        }"
        :loading="loading"
        :height="32"
        label="Web categories"
        item-text="name"
        item-value="name"
        multiple
        small-chips
        clearable
        deletable-chips
        :return-object="false" />
    </v-col>
    <v-col :cols="6">
      <gw-combobox
        v-model="product.brand"
        :items="brandsItem"
        :rules="{
          required: true,
          oneOf: brandsItem.map((r) => r.name)
        }"
        :loading="loading"
        label="Brand"
        item-text="name"
        item-value="name" />
    </v-col>
    <v-col :cols="12">
      <gw-textarea
        v-model="product.description"
        :loading="loading"
        label="Description"
        outlined
        dense
        hide-details />
    </v-col>
    <v-col :cols="12">
      <product-modal
        v-model="buyTheLookModal"
        @on-save="buytheLookHandle($event)" />
      <v-skeleton-loader
        :loading="loading"
        :height="230"
        type="image"
        class="input-skeleton-loader">
        <buy-the-look-input
          v-model="buyTheLookItems"
          @on-remove="removeBuytheLookItems($event)"
          @more-product-click="openBuyTheLookModal()" />
      </v-skeleton-loader>
    </v-col>
    <v-col :cols="12">
      <gw-drop-file
        v-model="product.photoUrls"
        :loading="loading"
        :img-aspect-ratio="0.75"
        label="Product Image"
        s3-path="/product-images"
        multiple />
    </v-col>
    <v-col :cols="6">
      <gw-text-field
        v-model="product.weight"
        :loading="loading"
        :rules="rules.numberFill"
        label="Weight"
        number />
    </v-col>
    <v-col :cols="6">
      <gw-text-field
        v-model="product.width"
        :loading="loading"
        :rules="rules.numberFill"
        label="Width"
        number />
    </v-col>
    <v-col :cols="6">
      <gw-text-field
        v-model="product.length"
        :loading="loading"
        :rules="rules.numberFill"
        label="Length"
        number />
    </v-col>
    <v-col :cols="6">
      <gw-text-field
        v-model="product.height"
        :loading="loading"
        :rules="rules.numberFill"
        label="Height"
        number />
    </v-col>
    <v-col :cols="12">
      <gw-autocomplete
        v-model="product.tags"
        :items="tagsItem"
        :loading="loading"
        label="Tags"
        item-text="name"
        item-value="name"
        multiple
        small-chips
        clearable
        deletable-chips
        :return-object="false"
        @input="tagsHandle($event)" />
    </v-col>
    <v-col cols="3">
      <v-switch
        v-model="product.preOrder"
        label="Pre-Order"
        :true-value="true"
        :false-value="false"
        @change="togglePreOrder()" />
    </v-col>
    <v-col cols="9">
      <v-text-field
        v-model="product.preOrderNote"
        label="Pre-Order Note"
        :disabled="!isPreOrder" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import BuyTheLookInput from './BuyTheLookInput.vue'
import ProductModal from './ProductModal.vue'

export default {
  components: {
    BuyTheLookInput,
    ProductModal
  },
  props: {
    buyTheLooks: {
      type: Array,
      default: () => []
    },
    categoriesItem: {
      type: Array,
      default: () => []
    },
    groupCategoryItem: {
      type: Array,
      default: () => []
    },
    prototypeCategoriesItem: {
      type: Array,
      default: () => []
    },
    brandsItem: {
      type: Array,
      default: () => []
    },
    collectionItem: {
      type: Array,
      default: () => []
    },
    tagsItem: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      product: {},
      buyTheLookInitialized: false,
      files: [],
      buyTheLookModal: false,
      buyTheLookItems: [],
      rules: {
        textFill: {
          required: true
        },
        numberFill: {
          regex: /^[+-]?([0-9]*[.])?[0-9]+$/
        }
      },
      sizeChart: {},
      isPreOrder: false
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    },
    value: {
      handler (val) {
        this.product = val
      },
      deep: true
    },
    buyTheLooks: {
      handler (value) {
        this.buyTheLookItems = value
      },
      deep: true
    }
  },
  async created () {
    if (this.value) {
      this.product = this.value
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    buytheLookHandle (products) {
      this.product.buyTheLooks = products.map((product) => product.id)
      this.buyTheLookItems = products
      this.buyTheLookModal = false
    },
    removeBuytheLookItems (index) {
      this.product.buyTheLooks.splice(index, 1)
      this.buyTheLookItems.splice(index, 1)
    },
    openBuyTheLookModal () {
      this.buyTheLookModal = true
    },
    groupCategoryHandle (groupCategories, limit = 20) {
      if (groupCategories.length > 20) {
        this.product.groupCategories = groupCategories.slice(0, limit)

        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถเลือก Group Categories ได้เกิน 20 Group Category',
          type: 'warning'
        })
      }
    },
    tagsHandle (tags, limit = 20) {
      if (tags.length > 20) {
        this.product.tags = tags.slice(0, limit)

        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถเลือก Tags ได้เกิน 20 Tags',
          type: 'warning'
        })
      }
    },
    togglePreOrder () {
      this.isPreOrder = this.product.preOrder
    }
  }
}
</script>

<style scoped>
.testtset {
  width: 100%;
  height: 250px;
  border: 1px dotted;
  border-radius: 25px;
}
.input-skeleton-loader .v-skeleton-loader__image {
  width: 100%;
  height: 100%;
}
</style>
