<template>
  <validation-observer
    ref="productform"
    tag="form"
    @submit.prevent="submitForm()">
    <v-container
      id="product-form"
      fluid>
      <form-product-info
        v-model="product"
        :categories-item="productAttributes.categories"
        :brands-item="productAttributes.brands"
        :tags-item="productAttributes.tags"
        :collection-item="productAttributes.collections"
        :group-category-item="productAttributes.groupCategories"
        :prototype-categories-item="productAttributes.prototypeCategories"
        :buy-the-looks="buyTheLookItems"
        :loading="loading" />
      <v-row
        v-if="!loading"
        id="skus-form"
        class="pa-5"
        justify="center">
        <v-col
          v-for="(skuForm, index) in skusForm"
          :key="`skus-info-${index}`"
          :cols="12"
          class="skus-info">
          <form-sku-info
            v-model="skusForm[index]"
            :colors="productAttributes.colors"
            :size="productAttributes.size"
            :disabled="isEditProduct" />
          <gw-icon-hover
            v-if="!isEditProduct"
            class="sku-bin"
            icon-name="mdi-delete"
            icon-hover="mdi-delete-empty"
            @click="removeSkus(index)" />
        </v-col>
        <v-col
          :cols="12"
          class="d-flex justify-center">
          <v-btn
            color="primary"
            :disabled="disabledAddMore"
            @click="addSkus()">
            {{ $t('button.Add Color') }}
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <form-table-skus
        v-if="skusTable.length > 0"
        v-model="skusTable"
        :placeholder="pricePlaceholder"
        :disabled="isEditProduct"
        @remove-skus-table="removeSkusTable($event)" />

      <form-size-chart
        v-model="sizeChart"
        class="mb-5" />
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-btn
            color="error"
            :disabled="!isEditProduct || !isRemoveable"
            @click="removeProduct(product.id)">
            {{ $t('button.Remove Product') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            type="submit">
            {{ submitButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SizeChartProvider from '@/resources/SizeChartProvider'
import PrototypeCategoryProvider from '@/resources/PrototypeCategoryProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import SizeValues from '@/assets/js/SizeValues'
import { getRole } from '@/assets/js/Authentication'
import FormProductInfo from '../components/FormProductInfo.vue'
import FormSkuInfo from '../components/FormSkusInfo.vue'
import FormTableSkus from '../components/FormTableSkus.vue'
import SizeChart from '../components/SizeChart.vue'

const ProductService = new ProductProvider()
const ProductAttributeService = new ProductAttributeProvider()
const SizeChartService = new SizeChartProvider()
const PrototypeCategoryService = new PrototypeCategoryProvider()

export default {
  components: {
    formProductInfo: FormProductInfo,
    formSkuInfo: FormSkuInfo,
    formTableSkus: FormTableSkus,
    formSizeChart: SizeChart
  },
  data () {
    return {
      loading: false,
      buyTheLookItems: [],
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: [],
        prototypeCategories: []
      },
      product: {
        additionalAttributes: [],
        brand: '',
        buyTheLooks: [],
        categories: [],
        codeTemp: '',
        colors: [],
        description: '',
        groupCategories: [],
        gwCollection: null,
        isOnWeb: false,
        model: '',
        name: '',
        note: '',
        options: [],
        photoUrls: [],
        preOrder: false,
        preOrderNote: '',
        price: 0,
        priority: 99999,
        productPrototype: {
          id: '',
          category: '',
          subCategory: '',
          designer: '',
          merchandiser: '',
          factory: '',
          margin: 2.8
        },
        sizeChartUrl: '',
        sizes: [],
        skuCodeTemps: [],
        skuModels: [],
        skus: [],
        specialPrice: 0,
        tags: [],
        variant: 'SKU',
        videoUrl: '',
        width: 1,
        height: 1,
        length: 1,
        weight: 1
      },
      skusForm: [{}],
      skusTable: [],
      isMapSkus: false,
      modelTimeOut: null,
      sizeChart: {
        productId: '',
        sizeChart: [{
          header: '',
          rows: [{
            key: '',
            value: ''
          }]
        }],
        sizeUnit: ''
      },
      createdSizeChart: false
    }
  },
  computed: {
    disabledAddMore () {
      if (this.isEditProduct) {
        return true
      }

      if (this.isMapSkus) {
        this.mapSkusTable()
      }

      return this.skusForm.some((r) => (!r.color || !r.color.name || !Array.isArray(r.size) || r.size.length === 0))
    },
    isEditProduct () {
      return this.$route.name === 'EditProduct' && !!this.$route.params.productId
    },
    submitButtonText () {
      return this.isEditProduct ? this.$t('button.Edit Product') : this.$t('button.Add Product')
    },
    pricePlaceholder () {
      return this.skusTable[0] && this.skusTable[0].price ? `${this.skusTable[0].price}` : ''
    },
    isRemoveable () {
      const role = getRole()

      return this.skusForm.every((sku) => !sku.gotStock) && role === 'developer'
    }
  },
  watch: {
    'product.model': {
      handler () {
        clearTimeout(this.modelTimeOut)
        setTimeout(() => {
          this.mapSkusTable()
        }, 1500)
      }
    }
  },
  created () {
    if (this.$route.name === 'CreateProduct') {
      this.initCreateProductPage()
    } else if (this.isEditProduct) {
      this.initEditProductPage(this.$route.params.productId)
    } else {
      this.setErrorPage(404)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async initCreateProductPage () {
      try {
        this.loading = true

        await this.getAttributes()

        this.sizeChart = {
          productId: '',
          sizeChart: [{
            header: '',
            rows: [{
              key: '',
              value: ''
            }]
          }],
          sizeUnit: ''
        }
      } catch (error) {
        console.error('initCreateProductPage: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
        this.isMapSkus = true
      }
    },
    async initEditProductPage (paramId) {
      try {
        this.loading = true

        const [product, sizeChartResponse] = await Promise.all([
          this.getProduct(paramId),
          this.getSizeChart(paramId),
          this.getAttributes()
        ])

        await this.mapProductData({
          ...product,
          productPrototype: product?.productPrototype || {
            id: '',
            category: '',
            subCategory: '',
            designer: '',
            merchandiser: '',
            factory: '',
            margin: 2.8
          }
        })

        this.sizeChart = sizeChartResponse || []
      } catch (error) {
        console.error('initEditProductPage: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
        this.isMapSkus = true
      }
    },
    async getSizeChart (id) {
      this.createdSizeChart = false

      const { data } = await SizeChartService.getSizeChartByProductId(id)

      if (!data || !data.id) {
        return {
          productId: id,
          sizeChart: [{
            header: '',
            rows: [{
              key: '',
              value: ''
            }]
          }],
          sizeUnit: ''
        }
      }

      this.createdSizeChart = true

      return data
    },
    sortSize (data) {
      return data.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    },
    async getProduct (id) {
      const { data } = await ProductService.getProductById(id)

      if (!data || !data.id) {
        const error = {
          code: 404,
          message: 'Product not found'
        }
        throw error
      }

      return {
        ...data,
        skus: this.sortSize(data.skus)
      }
    },
    async fetchManyProductsById (productIds) {
      const { data } = await ProductService.getManyProductById({ productIds })

      return productIds.map((id) => data.find((each) => each.id === id))
    },
    async mapProductData (data) {
      this.product = {
        ...this.product,
        ...data,
        gwCollection: data?.gwCollection || null,
        groupCategories: data?.groupCategories || [],
        preOrder: data?.preOrder || false,
        preOrderNote: data?.preOrderNote || ''
      }

      this.skusForm = []
      this.buyTheLookItems = await this.fetchManyProductsById(data.buyTheLooks)

      for (let i = 0; i < data.skus.length; i++) {
        const sku = data.skus[i]
        const formIndex = this.skusForm.findIndex((r) => r.color && r.color.name && r.color.name === sku.color.name)
        sku.size.skuId = sku.id

        if (formIndex === -1) {
          const color = this.mapColor(this.productAttributes.colors, sku.color.name)

          if (color && color.id) {
            this.skusForm.push({
              color,
              size: [sku.size],
              images: sku.images,
              preOrder: sku?.preOrder || false,
              preOrderNote: sku?.preOrderNote || '',
              gotStock: sku?.stock?.some((st) => st.onHandQty !== 0 || st.onReservedQty !== 0) || false
            })
          } else {
            const error = {
              code: 400,
              message: `${sku.color.name} not found in mapColor()`
            }

            throw error
          }
        } else {
          this.skusForm[formIndex].size.push(sku.size)
        }
      }

      this.mapSkusTable(this.skusForm, data.skus)
    },
    mapColor (colors, colorName) {
      for (let i = 0; i < colors.length; i++) {
        const color = colors[i]

        if (color.name === colorName) {
          return color
        }

        if (Array.isArray(color.children) && color.children.length > 0) {
          const newColor = this.mapColor(color.children, colorName)

          if (newColor && newColor.id) {
            return newColor
          }
        }
      }

      return null
    },
    async createProduct (product) {
      try {
        this.loading = true

        const { data } = await ProductService.createProduct(product)

        this.sizeChart.productId = data.id
        await SizeChartService.createSizeChart(this.sizeChart)

        this.setSnackbar({
          value: true,
          message: this.$t('Product created'),
          type: 'success'
        })

        this.$router.push({ name: 'Products' })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('createProduct', error)
      } finally {
        this.loading = false
      }
    },
    async updateProduct (product) {
      try {
        this.loading = true

        await ProductService.updateProduct(product.id, product)

        if (this.sizeChart && this.createdSizeChart) {
          await SizeChartService.updateSizeChart(this.sizeChart)
        } else if (this.sizeChart && this.sizeChart.sizeChart.length) {
          await SizeChartService.createSizeChart(this.sizeChart)
        }

        this.setSnackbar({
          value: true,
          message: this.$t('snackbar.Product updated'),
          type: 'success'
        })

        this.initEditProductPage(this.$route.params.productId)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('updateProduct', error)
      } finally {
        this.loading = false
      }
    },
    async removeProduct (id) {
      this.setModal({
        value: true,
        title: 'RemoveProduct',
        message: 'Do you want to remove the product',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveProduct(id)
      })
    },
    async confirmRemoveProduct (id) {
      try {
        const { data } = await ProductService.deleteProduct(id)
        const { data: sizeChartResponse } = await SizeChartService.getSizeChartByProductId(id)
        const { data: sizeChartDeleteResponse } = await SizeChartService.deleteSizeChart(id)

        if (sizeChartResponse && !sizeChartDeleteResponse) {
          const error = {
            code: 400,
            message: 'Size Chart Product ID not found or status not change'
          }
          throw error
        }

        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('Product removed'),
            type: 'success'
          })
          this.$router.push({ name: 'Products' })
        } else {
          const error = {
            code: 400,
            message: 'Product ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveProduct', error)
      }
    },
    async submitForm () {
      for (let i = 0; i < this.skusTable.length; i++) {
        if (i !== 0 && !this.skusTable[i].price) {
          this.skusTable[i].price = this.skusTable[0].price
        }
      }
      const valid = await this.$refs.productform.validate()
      if (valid) {
        const productData = {
          ...this.product,
          skus: this.skusTable.map((sku) => {
            const found = this.skusForm.find((s) => s.color.name.toLowerCase() === sku.color.name.toLowerCase())

            return {
              ...sku,
              images: found?.images || sku?.images || []
            }
          })
        }

        if (this.isEditProduct) {
          this.setModal({
            value: true,
            title: 'UpdateProduct',
            message: 'Do you want to update the product',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            onConfirm: () => this.updateProduct(productData)
          })
        } else {
          this.setModal({
            value: true,
            title: 'CreateProduct',
            message: 'Do you want to create the product',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            onConfirm: () => this.createProduct(productData)
          })
        }
      } else {
        this.setSnackbar({
          value: true,
          message: 'กรุณากรอกฟิลด์ให้ครบถ้วน (cost and price)',
          type: 'warning'
        })
      }
    },
    removeSkusTable (val) {
      const indexColor = this.skusForm.findIndex((r) => r.color.name === val.color.name)
      const indexSize = this.skusForm[indexColor].size.findIndex((r) => r.name === val.size.name)
      this.skusForm[indexColor].size.splice(indexSize, 1)
    },
    async getAttributes () {
      const { data } = await ProductAttributeService.getAllProductAttribute()
      const { data: prototypeCategories } = await PrototypeCategoryService.getAllMapped()

      this.productAttributes = {
        ...this.productAttributes,
        colors: Array.isArray(data.colors) ? this.getColor(data.colors) : [],
        brands: Array.isArray(data.brands) ? data.brands : [],
        size: Array.isArray(data.size) ? data.size : [],
        tags: Array.isArray(data.tags) ? data.tags : [],
        categories: Array.isArray(data.categories) ? data.categories : [],
        collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
        groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : [],
        prototypeCategories: prototypeCategories
          ?.map((d) => ({
            ...d,
            disabled: d.name === 'UNKNOWN'
          }))
          ?.sort((a, b) => a.id - b.id) || []
      }

      return data
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    addSkus () {
      this.skusForm.push({})
    },
    removeSkus (index) {
      this.skusForm.splice(index, 1)
    },
    mapSkusTable (skusForm = this.skusForm, skusTable = this.skusTable) {
      const newArr = []

      for (let index = 0; index < skusForm.length; index++) {
        const skuForm = skusForm[index]

        if (skuForm.color && skuForm.color.name) {
          if (newArr.length === 0 || !newArr.some((sku) => sku.color.name === skuForm.color.name)) {
            for (let j = 0; Array.isArray(skuForm.size) && j < skuForm.size.length; j++) {
              const skuIndex = skusTable.findIndex((sku) => {
                const isSkuIs = sku.id && skuForm.size[j].skuId && sku.id === skuForm.size[j].skuId
                const isColor = sku.color.name === skuForm.color.name
                const isSize = sku.size.name === skuForm.size[j].name
                return isSkuIs || (isColor && isSize)
              })
              const isDuplicate = skuIndex !== -1
              let skuCode = ''

              if (skusTable[skuIndex] && skusTable[skuIndex].code) {
                skuCode = skusTable[skuIndex].code
              } else if (!skuForm.size[j].skuId && this.product.model) {
                if (this.product.model.includes('.')) {
                  const splitted = this.product.model.split('.')
                  const model = splitted.slice(0, splitted.length - 1).join('.')
                  skuCode = `${model}.${skuForm.color.id}.${skuForm.size[j].id}`
                } else {
                  let isCodeDuplocate = true
                  const allSku = [...skusTable, ...newArr]
                  for (let k = 1; isCodeDuplocate; k++) {
                    isCodeDuplocate = allSku.some((r) => r.code && r.code === `${this.product.model}-${k}`)
                    skuCode = `${this.product.model}-${k}`
                  }
                }
              }

              const data = {
                id: isDuplicate ? skusTable[skuIndex].id : undefined,
                color: {
                  id: skuForm.color.id,
                  code: skuForm.color.code,
                  name: skuForm.color.name,
                  imageUrl: skuForm.color.imageUrl
                },
                size: skuForm.size[j],
                price: isDuplicate ? skusTable[skuIndex].price : undefined,
                cost: isDuplicate ? skusTable[skuIndex].cost : undefined,
                code: skuCode,
                ratio: isDuplicate ? skusTable[skuIndex].ratio : 2.8,
                status: isDuplicate ? skusTable[skuIndex].status : 'active',
                isOnWeb: isDuplicate ? skusTable[skuIndex].isOnWeb : false,
                images: skuForm.images && skuForm.images.length > 0 ? skuForm.images : [],
                preOrder: skusTable[skuIndex]?.preOrder ? skusTable[skuIndex].preOrder : false,
                preOrderNote: skusTable[skuIndex]?.preOrderNote ? skusTable[skuIndex].preOrderNote : ''
              }

              newArr.push(data)
            }
          } else {
            setTimeout(() => {
              this.removeSkus(index)
              this.setSnackbar({
                value: true,
                message: 'คุณเลือกสีซ้ำ',
                type: 'error'
              })
            }, 50)
          }
        }
      }

      this.skusTable = newArr
    }
  }
}
</script>

<style scoped>
.skus-info {
  position: relative;
  background-color: #fbfbfb;
  padding: 20px 50px;
  margin-bottom: 5px;
}
.sku-bin {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
